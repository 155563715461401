import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
// import faviconImage from "../images/favicon.png";
import twitterImageUrl from "../images/social-image-twitter.png";

const links = [];

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          description
          author
          keywords
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;

  keywords = (keywords || [])
    .concat(site.siteMetadata.keywords);

  const combinedMeta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: twitterImageUrl,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: twitterImageUrl
    },
  ];

  if (keywords.length) {
    combinedMeta.push({
      name: `keywords`,
      content: keywords.join(`, `),
    });
  }

  if (site.siteMetadata.author) {
    combinedMeta.push({
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    });
  }

  combinedMeta.concat(meta);

  let titleTemplate = `%s | ${site.siteMetadata.title}`;
  if (!title) {
    title = site.siteMetadata.title;
    titleTemplate = `%s`;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={combinedMeta}
      link={links}
      title={title}
      titleTemplate={titleTemplate}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default SEO;
