import React, { Fragment } from "react";
import PropTypes from "prop-types";

function Layout({ children }) {
  return (
    <div className="bg-gray-50">
      <div className="relative overflow-hidden flex flex-col min-h-screen">
        {children}
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
